import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useTitle } from './TitleContext' // 自定义 hook 获取动态标题

function TitleUpdater() {
    const location = useLocation()
    const { title } = useTitle() // 从 context 或全局状态获取当前标题

    useEffect(() => {
        if (title) {
            document.title = title
        }
    }, [title, location.pathname])

    return null
}

export default TitleUpdater