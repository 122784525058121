import './QuestionSection.css'
import React from 'react'
import { useQuery } from '@tanstack/react-query'
import {
    getQuestionList,
} from '../services/api.service'

export default function QuestionSection({
    title = 'Frequently Asked Questions',
    // questionList
    ids
}) {

    const {
        isPending,
        error,
        data: faqList,
    } = useQuery({
        queryKey: ['faqList', ids],
        queryFn: () => getQuestionList({ ids }),
    })

    // Show a loading spinner while the FAQ list is being fetched
    if (isPending) return <div style={{ textAlign: 'center' }}>Loading...</div>
    // If there was an error while fetching return an error message
    if (error) return 'About Page: An error has occurred: ' + error.message

    function onClick(e) {
        e.stopPropagation() // 防止事件冒泡
        // 确保获取到 `li`，避免 `e.target` 是 `span`
        const parent = e.currentTarget || e.target.closest('li')
        if (!parent) return
        const p = parent.querySelector('p') // 获取 <p>
        const span = parent.querySelector('span')
        if (p) {
            p.style.display = span.innerHTML === '+' ? 'block' : 'none'
            span.innerHTML = span.innerHTML === '+' ? '-' : '+'
        }
    }
    return (
        <div className="common-title-row about-question">
            <h3>{title}<span>&nbsp;</span></h3>
            {faqList?.data && (
                <div className="list">
                    <ul>
                        {faqList?.data?.map((item, index) => (
                            <li className='smsize:text-[14px]' key={index} onClick={(e) => {
                                console.log(e)
                                onClick(e)
                            }
                            }>
                                <span className='about-question-plus'>+</span>
                                {item?.title}
                                <p className='desc-content ql-editor smsize:text-[14px]' dangerouslySetInnerHTML={{ __html: item?.content }}></p>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    )
}
