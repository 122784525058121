import './MessageForm.css'
import React, { useState } from 'react'
import { AiFillInfoCircle } from 'react-icons/ai'
import { postMessage } from '@services/api.service'

export default function MessageForm({
    title,
}) {

    const [formData, setFormData] = useState({})
    const [result, setResult] = useState('')

    // get Data
    const getData = (key) => {
        return formData.hasOwnProperty(key) ? formData[key] : ''
    }

    // Set data
    const setData = (key, value) => {
        return setFormData({ ...formData, [key]: value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setResult('')

        try {
            await postMessage(formData)
            setFormData({})
            setResult("success")
        } catch (err) {
            setResult(err.data?.message)
        }
    }

    return (
        <div>
            {title && <div className='common-title-row'><h3>{title}<span>&nbsp;</span></h3></div>}
            <div className='form-group !max-w-[800px]'>
                <form onSubmit={handleSubmit} className='w-full'>
                    <div className='form-group-line'>
                        <label htmlFor='name'>Your Name
                            {/* (<span className='input-required'>*</span>) */}
                        </label>
                        <input value={getData('name')} onChange={(e) => setData('name', e.target.value)} type='text' placeholder='Enter your name' />
                    </div>
                    <div className='w-full flex items-center justify-between flex-row gap-5 smsize:flex-col smsize:gap-3'>
                        <div className='form-group-line w-full'>
                            <label htmlFor='email'>Your Email (<span className='input-required'>*</span>)</label>
                            <input value={getData('email')} onChange={(e) => setData('email', e.target.value)} type='email' placeholder='Enter your email' required />
                        </div>
                        <div className='form-group-line w-full'>
                            <label htmlFor='phone'>Phone Number (<span className='input-required'>*</span>)</label>
                            <input value={getData('phone')} onChange={(e) => setData('phone', e.target.value)} type='text' placeholder='Enter phone number' required />
                        </div>
                    </div>
                    {/* <div>
                    <label htmlFor='subject'>Subject
                    </label>
                    <input value={getData('subject')} onChange={(e) => setData('subject', e.target.value)} type='text' placeholder='Enter a title' />
                </div> */}
                    <div className='form-group-line'>
                        <label htmlFor='content'>Your Message
                            {/* (<span className='input-required'>*</span>) */}
                        </label>
                        <textarea rows={5} value={getData('content')} onChange={(e) => setData('content', e.target.value)} placeholder='Enter text here' />
                    </div>
                    <div>
                        <button type='submit' className='common-button'>SEND</button>
                    </div>
                    {result &&
                        <div className={result === 'success' ? 'success' : 'error'}>
                            <label><AiFillInfoCircle /> {result === 'success' ? 'Successfully, We will contact you as soon as possible!' : result}</label>
                        </div>
                    }
                </form>
            </div>
        </div>
    )
}
