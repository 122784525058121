import './ServiceSection.css'
import React from 'react'
import TemplateContent from 'pages/about/components/TemplateContent'

export default function ServiceSectionNew({
    content
}) {
    if (!content) return null

    if (content?.is_template) {
        return <TemplateContent content={content} />
    }

    return (
        <section className="service-section">
            <div className="service-header">
                <h3>{content?.title}</h3>
                <p className='ql-editor' dangerouslySetInnerHTML={{ __html: content?.content }}></p>
            </div>
        </section>
    )
}
