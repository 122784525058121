import React from 'react'
import './NavbarTop.css'
import {
  AiOutlineDollarCircle,
  AiOutlineFieldTime,
  AiOutlineCrown,
  AiOutlineSafety
} from 'react-icons/ai'
import { Popover, Tag } from 'antd'
import TopLocation from './TopLocation'
import helper from '@utils/helper'
import { useSelector } from 'react-redux'

function getStoreStatus(settings) {
  const weekday = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ]
  // const d = new Date()
  // var hours = d.getHours()
  // var mins = d.getMinutes()
  // var day = d.getDay()
  // let status = 'Closed'
  // // saturaday 10:00 a.m. - 3:30 p.m.
  // // if (day === 6 && hours >= 10 && (hours < 16 || (hours === 16 && mins < 30))) {
  // // saturaday 10:00 a.m. - 4:00 p.m.
  // // if (day === 6 && hours >= 10 && (hours < 16 || (hours === 16 && mins < 30))) {
  // // sat 9:30 to 4:00
  // if (
  //   day === 6 &&
  //   // (hours >= 10 || (hours === 9 && mins >= 30)) &&
  //   // (hours < 16 || (hours === 16 && mins < 1))
  //   hours >= 10 &&
  //   hours < 15
  // ) {
  //   status = 'Open'
  // }
  // // 1-5 9:30 a.m. - 5:30 p.m.
  // if (
  //   day >= 1 &&
  //   day <= 5 &&
  //   // (hours >= 10 || (hours === 9 && mins >= 30)) &&
  //   // (hours < 17 || (hours === 17 && mins < 30))
  //   hours >= 9 &&
  //   hours < 17
  // ) {
  //   // 1-5 9:30 a.m. - 6:00 p.m.
  //   // if (day >= 1 && day <= 5 && (hours >= 10 || (hours === 9 && mins >= 30)) && (hours < 18)) {
  //   // 1-5 9am - 6:00 p.m.
  //   // if (day >= 1 && day <= 5 && hours >= 9 && hours < 18) {
  //   status = 'Open'
  // }
  const d = new Date()
  let status = 'Closed'
  let dayText = weekday[d.getDay()]
  let openTime = `${settings?.monday_friday_start} - ${settings?.monday_friday_end}`
  if (helper.isStoreOpen(settings?.monday_friday_start, settings?.monday_friday_end)) {
    status = 'Open'
  }
  if (dayText === 'Saturday') {
    openTime = `${settings?.saturday_start} - ${settings?.saturday_end}`
    if (helper.isStoreOpen(settings?.saturday_start, settings?.saturday_end)) {
      status = 'Open'
    }
  } else if (dayText === 'Sunday') {
    openTime = 'Closed'
  }
  return {
    dayText: dayText,
    status: status,
    openTime: openTime
  }
}

export default function NavbarTop() {


  const settings = useSelector(state => state.content.settings)

  const storeStatus = getStoreStatus(settings)
  return (
    <div className="topline-wrapper">
      <div className="topline-content">
        <div className="topline-left">
          <div className="topline-item">
            <AiOutlineDollarCircle size={32} />
            <h3>Best Value</h3>
          </div>
          <div className="topline-item">
            <AiOutlineCrown size={32} />
            <h3>Quality Products</h3>
          </div>
          <div className="topline-item">
            <AiOutlineSafety size={32} />
            <h3>Financing Available</h3>
          </div>
        </div>
        <div className="topline-right">
          <TopLocation title="Location" />
          <Popover
            placement="bottomRight"
            title="Open Hours"
            content={
              <div>
                <p>Monday - Friday: {helper.formatTime(settings?.monday_friday_start)} - {helper.formatTime(settings?.monday_friday_end)}</p>
                <p>Saturday: {helper.formatTime(settings?.saturday_start)} - {helper.formatTime(settings?.saturday_end)}</p>
                <p>Sunday: Closed</p>
              </div>
            }
          >
            <div className="topline-status">
              <Tag
                color={storeStatus.status === 'Open' ? '#87d068' : '#B33030'}
              >
                <strong> {storeStatus.status} </strong>
              </Tag>
              <strong> {storeStatus.dayText} </strong>
              <AiOutlineFieldTime size={24} />
              <div className="topline-opentime">{storeStatus.openTime}</div>
            </div>
          </Popover>
        </div>
      </div>
    </div>
  )
}
