import React, { useEffect } from 'react'
import { message, Rate } from 'antd'
import { addRating } from '@services/api.service'

const AverageRating = ({
    id,
    averageRating,
    ratingValue,
    reviewCount,
    setIsRefresh
}) => {

    const [isEdit, setIsEdit] = React.useState(true)
    useEffect(() => {
        let ratingData = localStorage.getItem('ratingData')
        if (ratingData) {
            ratingData = JSON.parse(ratingData)
            if (ratingData.includes(id)) {
                setIsEdit(false)
            }
        }
    }, [id])

    const onChange = async (value) => {
        try {
            const res = await addRating(id, value)
            console.log('add rating', res)
            message.success('Rating added successfully')
            let ratingData = localStorage.getItem('ratingData')
            ratingData = ratingData ? JSON.parse(ratingData) : []
            ratingData.push(id)
            localStorage.setItem('ratingData', JSON.stringify(ratingData))
            setIsRefresh(true)
        } catch (err) {
            console.log(err.data?.message || 'add rating error')
        }
        setIsEdit(false)
    }

    return <div className='rating-wrap'>
        <h4 className='font-bold'>Average rating:</h4>
        <Rate
            disabled={!isEdit}
            value={averageRating}
            onChange={onChange}
            tooltips={[averageRating, averageRating, averageRating, averageRating, averageRating]}
        />
        <p>({reviewCount} reviews)</p>
    </div>
}

export default AverageRating
