import './ProductView.css'
import React, { useState, useEffect } from 'react'
import { config } from '@config'
import TopLocation from '../../../components/TopLocation'
import ProductIcon3PMS from './ProductIcon3PMS'
import ProductNote from './ProductNote'
import ProductSchema from './ProductSchema'
import AverageRating from './AverageRating'
import { Alert } from 'antd'

// On Sale' : 'On Clearance
export const ProductStatus = [
    {
        name: 'On Sale',
        className: 'status_on_sale'
    },
    {
        name: 'On Clearance',
        className: 'status_on_clear'
    },
    {
        name: 'Premium',
        className: 'status_premium'
    }
]

export default function ProductView({
    user,
    currentRow,
    setShow,
    setIsRefresh
}) {

    const [picture, setPicture] = useState('')

    useEffect(() => {
        if (currentRow && currentRow?.pictures) {
            setPicture(currentRow.pictures.split(',')[0])
        } else if (currentRow && currentRow?.pattern_picture) {
            setPicture(currentRow.pattern_picture)
        }
    }, [currentRow])

    // const localtionId = user ? (currentRow?.location || 0) : 0
    const localtionId = currentRow?.location || 0
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent)

    let textLessFour = currentRow?.count === 4 ?
        'Note: This item is the last set (4 pieces) and is a clearance item.' :
        `Note: This item is the last ${currentRow?.count} pieces and is a clearance item.`

    return (
        <>
            <ProductSchema product={currentRow} />
            <div className="product-view">
                {currentRow && (
                    <div className="product-view-grid">
                        <div className="product-view-item">
                            <div className="product-pictures">
                                <ProductIcon3PMS product={currentRow} />
                                <div className="product-pictures-big flex items-center justify-center">
                                    {picture && <img src={picture} alt={currentRow.title} />}
                                </div>
                                <div className="product-pictures-small">
                                    {currentRow.pictures && currentRow.pictures.split(',').map((item, index) => (
                                        <div onClick={() => {
                                            setPicture(item)
                                        }} key={index} className={`${picture === item ? 'active' : ''} flex items-center justify-center`}>
                                            <img src={item} alt={currentRow.title} />
                                        </div>
                                    ))}
                                    {currentRow.pattern_picture && (
                                        <div onClick={() => {
                                            setPicture(currentRow.pattern_picture)
                                        }} key={99} className={`${picture === currentRow.pattern_picture ? 'active' : ''} flex items-center justify-center`}>
                                            <img src={currentRow.pattern_picture} alt={currentRow.pattern_title} />
                                        </div>
                                    )}
                                    {/* <div className='disabled'></div>
                                    <div className='disabled'></div>
                                    <div className='disabled'></div>
                                    <div className='disabled'></div> */}
                                    {/* <div><img width="120" height="80" src='/images/2021-10-12.webp' alt={currentRow.title} /></div>
                                <div><img width="120" height="80" src='/images/2021-11-11.webp' alt={currentRow.title} /></div>
                                <div><img width="120" height="80" src='/images/winter-tires.webp' alt={currentRow.title} /></div> */}
                                </div>
                            </div>
                            <div className="product-view-content">
                                <h1 className='font-bold text-xl leading-6'>{currentRow.title}</h1>
                                <p>{currentRow.description}</p>
                                {currentRow.status_type > 0 &&
                                    // <span className={currentRow.status_type === 1 ? 'status_on_sale' : 'status_on_clear'}>{product.status_type === 1 ? 'On Sale' : 'On Clearance'}</span>
                                    <span className={ProductStatus[currentRow.status_type - 1].className}>{ProductStatus[currentRow.status_type - 1].name}</span>
                                }
                                <div className='product-price'>
                                    <h4><span className='font-bold'>(Price) Retail: </span>${Number(currentRow.retail_price)}</h4>
                                    {user && user.is_view && (
                                        <>
                                            {Number(currentRow?.price) > 0 && (
                                                <h4 className='product-price-partner'>Partner: ${Number(currentRow?.price)}</h4>
                                            )}
                                            {user.is_vip && Number(currentRow.vip_price) > 0 ? (
                                                <h4 className='product-price-vip'>VIP: ${Number(currentRow.vip_price)}</h4>
                                            ) : null}
                                        </>
                                    )}
                                </div>
                                {user && user.is_view && (
                                    <>
                                        <font color='#ff0000'>Note: $5 per tire recycling is not included in the price.</font>
                                        <font color='#ff0000'>**Tire Recycling Fee Charge exempt for registered tax remittance accounts.**</font>
                                    </>
                                )}
                                {/* {user && currentRow.status_visible !== 1 ? (
                                    <h4>Quantity: {currentRow.count >= 16 ? '16+' : currentRow.count}</h4>
                                ) : null} */}
                                {currentRow.count !== 0 && ((user && currentRow.status_visible !== 1) || currentRow.count <= 4) && (
                                    <h4><span className='font-bold'>Quantity: </span>{currentRow.count >= 16 ? '16+' : currentRow.count}</h4>
                                )}
                                {currentRow.count !== 0 && currentRow.count <= 4 && (
                                    <Alert message={textLessFour} type="error" />
                                )}
                                <h4><span className='font-bold'>Location: </span>{config.location[localtionId]}</h4>
                                <ProductNote />
                                {currentRow?.tread_depth && (
                                    <h4><span className='font-bold'>Tread Depth: </span>{currentRow.tread_depth} <span style={{
                                        fontSize: '12px',
                                        fontWeight: 400
                                    }}>(32nd inch)</span></h4>
                                )}
                                {currentRow.tags && (
                                    <h4><span className='font-bold'>Tags: </span>{currentRow.tags}</h4>
                                )}
                                <div className='product-tel-sms'>
                                    <a href={`tel:${config.phone}`} className="call-now"><button className="common-button">Call Now</button></a>
                                    <a href={`sms:${config.phone}${isIOS ? '&' : '?'}body=${encodeURIComponent('Product: ' + currentRow.title)}`} className="text-now"><button className="common-button phone-sms">Text Now</button></a>
                                </div>
                                <AverageRating
                                    id={currentRow.id}
                                    averageRating={currentRow?.averageRating || 5}
                                    ratingValue={currentRow?.ratingValue || 5}
                                    reviewCount={currentRow?.reviewCount || 1}
                                    setIsRefresh={setIsRefresh}
                                />
                                {currentRow.content && currentRow.content !== '<p><br></p>' && (
                                    <div className="product-view-desc">
                                        <h3>Description</h3>
                                        <p className='desc-content ql-editor' dangerouslySetInnerHTML={{ __html: currentRow.content }}></p>
                                    </div>
                                )}
                                <button type="button" className='btn-readit bg-gray-200 rounded-md text-[14px]' onClick={() => setShow(true)}>Warranty Policy</button>
                                <button type="button" className='btn-readit bg-gray-200 rounded-md text-[14px]'><TopLocation title={'Open Our Store Map'} /></button>
                            </div>
                        </div>
                    </div>
                )}

            </div>

        </>
    )
}
