import './ProductSection.css'
import React from 'react'
import { Link } from 'react-router-dom'
// import { useDispatch } from 'react-redux'
// import { setFilterTypes } from '@reducers/contentReducer'
import helper from '@utils/helper'
import MyImage from './MyImage'

export default function ProductSection({
    title,
    productTypeList
}) {

    // const dispatch = useDispatch()

    return (
        <section className="product-section">
            {title && (
                <div className="product-header">
                    <h3>{title}<span>&nbsp;</span></h3>
                </div>
            )}
            <section className="tire-types-section">
                <div className="tire-types-content">
                    {productTypeList && productTypeList.map(item => {
                        let link = `/tires/list/${helper.formatTitleForLink2(item.title)}`
                        let title = item.title
                        if (item.title === 'Steel Wheels') {
                            link = '/wheels'
                            title = 'New Rims'
                        }

                        return (
                            <div key={item.id} className="tire-types-content-item">
                                <Link to={link}>
                                    {/* <img src={item.picture} alt={item.title} /> */}
                                    <MyImage src={item.picture} alt={title} />
                                </Link>
                                <Link to={link}><h3>{title}</h3></Link>
                            </div>
                        )
                    })}
                </div>
            </section>
        </section>
    )
}
