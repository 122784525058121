import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
// import { BrowserRouter } from 'react-router-dom'
import { BrowserRouter as Router } from 'react-router-dom';
import { configureStore } from '@reduxjs/toolkit'
import { Provider } from 'react-redux'
import { TitleProvider } from './contexts/TitleContext'
import TitleUpdater from './contexts/TitleUpdater'
import allReducers from './reducers'
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

// define the store of redux
const store = configureStore({
  reducer: allReducers,
  devTools: false
})

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // wrap the app with Provider
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <TitleProvider>
        <Router>
          <TitleUpdater />
          <App />
        </Router>
      </TitleProvider>
    </QueryClientProvider>
  </Provider>
)

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.register("service-worker.js")
}