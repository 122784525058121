import React from 'react'
import QuestionSection from '@components/QuestionSection'
import BannerSection from '@components/BannerSection'
import Navigation from '@components/Navigation'
// import { useSelector } from 'react-redux'

export default function Faq() {
    // get questions from redux
    // const questions = useSelector((state) => state.content.questions)
    return (
        <>
            <BannerSection
                color='#fff'
                title='Frequently Asked Questions'
                background='/images/faq-banner.webp'
            />
            <Navigation
                title='Frequently Asked Questions'
                titleLink='/faq'
                imageUrl='/images/faq-banner.webp'
            />
            <QuestionSection
                key='question-section'
                title='Frequently Asked Questions'
            // questionList={questions}
            />
        </>
    )
}
