import './ServiceSection.css'
import React from 'react'

export default function ServiceSection({
    title,
    description
}) {

    return (
        <section className="service-section">
            <div className="service-header">
                <h3>{title}</h3>
                <p className='ql-editor' dangerouslySetInnerHTML={{ __html: description }}></p>
            </div>
        </section>
    )
}
